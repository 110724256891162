.cont {
  /* background: #f1f1f1; */
  *margin-top: 2%;
  padding-left: 10% !important;
  padding-right: 10% !important;
  border: 0.5px solid #878484;
border-radius: 0px 10px 10px 10px;
padding-bottom: 5%;
}

.cont label {
  padding-bottom: 0.75rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 520;
  font-size: 20px;
  line-height: 23px;
 
  text-align: justify;
  font-variant: normal;

  color: #0e0e0e;
}

.divBtn {
  height: 47px;

  display: flex;
  justify-content: space-between;
  margin-top: 6%;
}
.divBtn button {
  background: #eebb2e;
  border-radius: 10px;
  border: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  padding: 12px 16px;

  text-align: justify;

  color: #fdfdfd;
}
.Firstpadd {
  padding-top: 8%;
}

@media screen and(max-width: 767px) {
  .emailinput{
    /* margin-top: 20px; */
  }
  .gstinput{
    margin-top: 20px;
  }
}