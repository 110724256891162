.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imglogo {
    height: 40px;
}

.logininput1 {
    position: relative !important;

}

.icon1 {
    position: absolute !important;
    /* left: 15px !important; */
    /* top: 150px; */
    right: 15px !important;
    bottom: 10px;
}

.hr {
    width: 100% !important;
    height: 2px;
    background: #2A2381;
    margin: 15px 0;
}

.main-color {
    color: #2A2381;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

.txt1 {

    font-family: 'IvyOra Text';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #F1F1F1;
}

.txt2 {

    width: 109px;
    height: 21px;
    font-family: 'IvyOra Text';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #F1F1F1;

}

.close-btn {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999999999;
    color: rgba(42, 35, 129, 1);
}

.cursor-pointer {
    cursor: pointer !important;
}

.search_box {
    display: flex !important;
    justify-content: end !important;
    position: relative !important;
    place-items: center !important;
    width: 50%;
}

.search_input2 {
    background: #F1F1F1 !important;
    color: #4D4D4D !important;
    padding: 15px 20px !important;
    border: none !important;
    border-radius: 20px !important;
}

.search_input1 {
    background: rgba(62, 60, 60, 1) !important;
    color: white !important;
    padding: 15px 20px !important;
    border: none !important;
    border-radius: 20px !important;
}

.search_input1::placeholder {
    color: rgba(181, 181, 181, 1) !important;
    opacity: 1;
}

.bg-black {
    background: black !important;
}

.search_icon {
    color: #B5B5B5;
    position: absolute;
    top: 21px;
    right: 30px;
    background: #3E3C3C;
}

.search_icon3 {
    color: #4D4D4D;
    position: absolute;
    top: 21px;
    right: 30px;
    background: #F1F1F1;
}

.search_icon1 {
    color: #B5B5B5;
    position: absolute;
    top: 13px;
    right: 10px;
    background: #3E3C3C;
}

.search_icon2 {
    color: #4D4D4D;
    position: absolute;
    top: 13px;
    right: 10px;
    background: #F1F1F1;
}

.nav_text {
    padding-top: 15px;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
}

.nav_text:hover {
    color: rgb(91, 88, 88);
}

.login_btn {
    background: #3E3C3C !important;
    color: #B5B5B5 !important;
    border-radius: 10px !important;
}

.login_btn1 {
    background: #F1F1F1 !important;
    color: #4D4D4D !important;
    border-radius: 10px !important;
}

.menu_btn {
    padding: 2px 5px 5px 5px !important;
    border-radius: 10px !important;
    cursor: pointer !important;
}

.search_bar {
    background-color: #3E3C3C;
    color: #B5B5B5;
    padding: 13px 25px 13px 15px;
    height: 25px;
    width: 100%;
    border-radius: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    opacity: 70%;
}

.searchfield {
    margin-left: 55px;
}

.txt3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #B5B5B5;
    padding-left: 50px;
}

.txt4 {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    color: #B5B5B5;
    padding-left: 15px;
}

.avatar1 {
    width: 28px !important;
    height: 28px !important;
    color: #B5B5B5;
}

.avatar2 {
    width: 28px !important;
    height: 28px !important;
    color: #F1F1F1;
}

.btn1 {

    background-color: #3E3C3C;
    border-radius: 20px;
    font-size: 20px;
    line-height: 23px;
    color: #B5B5B5;
    font-family: 'Roboto';
    opacity: 70%;
}

.loginsignin {
    margin-left: 55px;
}

.pic1 {
    height: 100%;
    width: 100%;
}

.header_con {
    background-color: rgb(79, 76, 76, 0.5);
}

.otpStyle::-webkit-outer-spin-button,
.otpStyle::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.disabled {
    opacity: 0.5;
}

.fs-12 {
    font-size: 14px;
    font-weight: bold;

}

.red {
    color: red;
}

.trans {
    color: rgba(42, 35, 129, 0);
}