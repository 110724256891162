* {
    margin: 0;
    padding: 0;
}



.para {
    color: #f7f7f7;
    font-family: 'IvyOra Text';
    font-size: 1.2em;
}


span {
    font-weight: 400;
    font-size: 20px;
}

.user_link {
    text-decoration: none;
    font-size: 16px;
    color: rgba(42, 35, 129, 0.8);
    cursor: pointer;
    font-weight: 600;
}


.signup_txt {
    font-size: 22px;
}


/* ======================= forget passwaord =========================== */
form .forget_heading {
    margin-top: 7rem;
    font-family: 'IvyOra Text';
    font-weight: 700;
}

.back_login a {
    text-decoration: none;
    cursor: pointer;
    color: rgba(42, 35, 129, 0.8);
    font-weight: 500;
    margin-top: 7rem;
}

/* =========================== Resendmail ================================ */
.resend_mail {
    margin: 15rem 0rem;
    font-size: 20px;
}

.resend_mail_link span {
    text-decoration: none;
    font-size: 16px;
    color: #2A2381;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (max-width: 355px) {
    .form_width {
        margin-right: 12px !important;
    }
}

@media only screen and (min-width: 360px) and (max-width: 640px) {
    .form_width {
        margin-right: 4px !important;
    }
}


/* ========================newfilesCss=============================== */
.logininput2{
    position: relative;
}
.icon2{
    position: absolute;
    right: 20px !important;
    /* top: 150px; */
    bottom: 11px;
}
.main_container {
    background: linear-gradient(90.22deg, #DA7B02 -1.57%, #EEBB2E 99.81%);
    border-radius: 10px;
}

.main_container h1 {
    color: #FFFFFF;
    font-weight: 700;
    font-family: 'IvyOra Text';
}

.main_container .sub_heading {
    font-family: 'IvyOra Text';
    font-weight: 500;
    font-size: 24px;
    color: #FDFDFD;
}

.main_container .sub_heading2 {
    font-family: 'IvyOra Text';
    color: #FDFDFD;
    font-size: 18px;
}

.main_container .sub_heading2 {
    font-family: 'IvyOra Text';
    color: #FDFDFD;
}

form {
    background: none !important;
}

.main_container form input {
    background: #FDFDFD;
    border-radius: 10px;
    color: #5749E3;
    font-weight: 500;
    font-size: 20px;
}

form button {
    background: rgba(42, 35, 129, 0.8) !important;
    border-radius: 10px !important;
    color: #FDFDFD !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}

.main_container .last_para {
    color: #2A2381;
    font-weight: 400;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
}

.main_container {
    position: relative;
}

.main_container .cross {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

form .input_feild {
    color: #5749E3 !important;
}

form .pass_feild {
    color: #5749E3 !important;
    font-weight: 500;
}

.signUp_link {
    color: #2A2381;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
}

@media screen and (max-width: 360px) {
    .main_container .sub_heading {
        font-size: 20px;
    }

    .last_section .signUp_link {
        font-size: 17px;
    }

    .last_section .last_para {
        font-size: 17px;
    }
}

@media screen and (max-width: 455px) {
    .last_section .last_para {
        font-size: 16px;
    }

    .last_section .signUp_link {
        font-size: 16px;
    }
}

@media screen and (max-width: 343px) {
    .last_section .last_para {
        font-size: 14px;
    }

    .last_section .signUp_link {
        font-size: 14px;
    }
}

@media screen and (max-width: 365px) {
    form .request_otp {
        font-size: 13px;
    }
}

/*  ==========================LoginOTP=========================== */
.main_container .back_button {
    position: absolute;
    top: 12px;
    left: 0;
}

form .otp {
    width: 50px;
    color: #5749E3 !important;
}

form .request_otp {
    text-decoration: none;
    color: rgba(42, 35, 129, 0.8);
    font-weight: 500;
    cursor: pointer;
}

.main_container .reset_msg p {
    font-size: 24px;
}

/* ==========================newfileCss End=================================== */

.first_page {
    height: 100vh;
    width: 100%;
    /* background: url("images/image2.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'IvyOra Text';
    color: #F1F1F1;
}

.arrowDownIcon {
    position: relative;
    bottom: -95%;
}

.search_btn {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #2A2381 !important;
    text-transform: capitalize !important;
}
.search_btn1 {
    width: 100%;
    height: 30px;
    display: flex;
    color: white !important;
    align-items: center;
    background-color: rgb(3, 14, 60) !important;
    text-transform: capitalize !important;

}

.top_text_box{
    width: 36%;
    padding-top: 10px;
}

.top_text1{
    font-size: 30px;
    font-style: italic;
}

.top_text2{
    font-size: 12px;
    margin-top: -12px;
}

.flex_item_center{
    display: flex;
    align-items: center;
}

.select_style{
    background-color: #3f3f3f!important;
    width: 100%!important;
    padding:5px!important;
    text-align: center!important;
    color: white!important;
    border-radius: 5px!important;
    font-size: 18px!important;
    border: none!important;
}
.option_style{
    background: #555555;
    text-align: center;
}


.explore_btn {
    font-family: 'Roboto';
    padding: 10px 15px ;
    color: #FFFFFF;
    background: none;
    display: flex;
    align-items: center;
    border: 1px solid #DA7B02;
    border-radius: 7px;
    margin-top: 30px;
}

.explore_btn:hover{
    background: rgba(0,0,0,0.4);
}

.text-orang{
    color: orange;
}

.emoji {
    height: 45px;
    width: 44px;
}


.selectresponsive{
    background-color: #3F3F3F;
    width: 100%;
    padding: 5px;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Roboto';
}

.optionResponsive{
   font-family: 'Roboto';
   font-size: 18px;
}

.option2Res{
    background-color: #555555;
    text-align: center;
}

.find_txt{
    font-family: 'IvyOra Text';
    font-size: 18px;
}

.main_div{
    background-color: #252424;
    border-radius: 5px;
    margin: auto;
    max-width: 1300px;
}

.first_div{
    height: 20%;
}

.h2_txt{
    color: white;
    font-family: 'IvyOra Text';
    font-style: italic;
    font-size: 40px;
}

.p_txt{
    color: white;
    width: 300px;
    
}

.home_data{
    display: flex;
    align-items: center;
    justify-content: start;

}

.div_emoji{
    border: 1px solid gray;
    border-radius: 5px;
    width: 60px;
    padding: 5px;
    height: 55px;
    margin: 55px 15px 0px 0px;
}

.div_emoji:hover{
    background: rgba(0,0,0,0.4);
 }

.text-Ivy{
    font-family: IvyOra Text!important;
}

.pr_4{
    padding-right: 30px;
}

.filter_bg{
    background: #252424 !important;
}

.res_text{
    font-size: 18pt!important;
    color: #F1F1F1 !important;
}

@media (max-width:400px) {
    .img_text_con {
        width: 0% !important
    }
}

@media (max-width:900px) {
    .pr_4{
        padding-right: 0;
    }
    .res_text{
        font-size: 16pt!important;
    }
}

@media (max-width:575px) {
    .pb_1{
        padding-bottom: 5px;
    }
    .res_text{
        font-size: 14pt!important;
    }
}