*{
    margin: 0;
    padding: 0;
}


.para{
    color: #f7f7f7;
    font-family: 'IvyOra Text';
    font-size: 1.2em;
}

span{
    font-weight: 400;
    font-size: 20px;
}
.user_link{
    text-decoration: none;
    font-size: 16px;
    color: rgba(42, 35, 129, 0.8);
    cursor: pointer;
    font-weight: 600;
}

.signup_txt{
    font-size: 22px;
}

/* ======================= forget passwaord =========================== */
form .forget_heading{
    margin-top: 7rem;
    font-family: 'IvyOra Text';
    font-weight: 700;
}
.back_login a{
    text-decoration: none;
    cursor: pointer;
    color: rgba(42, 35, 129, 0.8);
    font-weight: 500;
    margin-top: 7rem;
}

/* =========================== Resendmail ================================ */
.resend_mail{
    margin: 15rem 0rem;
    font-size: 20px;
}
.resend_mail_link span{
    text-decoration: none;
    font-size: 16px;
    color:  #2A2381;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (max-width: 355px) {
    .form_width{
        margin-right: 12px !important;
    }
  }

  @media only screen and (min-width: 360px) and (max-width: 640px)  {
    .form_width{
        margin-right: 4px !important;
    }
  }


  /* ========================newfilesCss=============================== */

  
  .main_container{
    background: linear-gradient(90.22deg, #DA7B02 -1.57%, #EEBB2E 99.81%);
    border-radius: 10px;
}
.main_container h1{
 color: #FFFFFF;
 font-weight: 700;
 font-family: 'IvyOra Text';
}
.main_container .sub_heading{
    font-family: 'IvyOra Text';
    font-weight: 500;
    font-size: 24px;
    color: #FDFDFD;
}
.main_container .sub_heading2{
    font-family: 'IvyOra Text';
    color: #FDFDFD;
    font-size: 18px;
}
.main_container .sub_heading2{
    font-family: 'IvyOra Text';
    color: #FDFDFD;
}
form{
    background: none !important;
}
.main_container form input{
    background: #FDFDFD;
    border-radius: 10px;
    color: #5749E3;
    font-weight: 500;
    font-size: 20px; 
}

form button{
    background: rgba(42, 35, 129, 0.8) !important;
    border-radius: 10px !important;
    color: #FDFDFD !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}
.main_container .last_para{
    color: #2A2381;
    font-weight: 400;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
}
.main_container {
    position: relative;
}
.main_container .cross {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
form .input_feild{
    color:#5749E3 !important;
}
form .pass_feild{
    color:#5749E3 !important;
    font-weight: 500;
}
.signUp_link{
    color: #2A2381;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
}
.otpContainer{
    display: flex;
    justify-content: center;
    width: 100% !important;
    /* border: 1px solid red; */
}
.otpContainer input{
    width: 50px;
    height: 60px;
    border: none;
    margin-right: 15px;
    /* padding: 5px 0 0 20px; */
}
.otpContainer button{
    width: 100% !important;
}

@media screen and (max-width: 360px) {
    .main_container .sub_heading {
       font-size: 20px;
    }
    .last_section .signUp_link{
        font-size: 17px;
    }
    .last_section .last_para{
        font-size: 17px;
    }
  } 
@media screen and (max-width: 455px) {
    .last_section .last_para{
        font-size: 16px;
    }
    .last_section .signUp_link{
        font-size: 16px;
    }
  } 
@media screen and (max-width: 343px) {
    .last_section .last_para{
        font-size: 14px;
    }
    .last_section .signUp_link{
        font-size: 14px;
    }
  } 
@media screen and (max-width: 365px) {
    form  .request_otp{
        font-size: 13px;
        cursor: pointer;
    }
  } 

 /*  ==========================LoginOTP=========================== */
.main_container .back_button{
  position: absolute;
  top: 12px;
  left: 0;
}
 form .otp{
   width: 50px;
    color:#5749E3 !important;
}
form .request_otp{
    text-decoration: none;
    color: rgba(42, 35, 129, 0.8);
    font-weight: 500;
    cursor: pointer;
    size:4rem;
    /* border: 1px solid red; */
}
.form_container .request_otp{
    text-decoration: none;
    color: rgba(42, 35, 129, 0.8);
    font-weight: 500;
    cursor: pointer;
    size:4rem;
    /* border: 1px solid red; */
}

.main_container .reset_msg p{
    font-size: 24px;
} 

/* ==========================newfileCss End=================================== */