.show {
  display: none;
}
.mainContainer{
  min-height: 80vh;
}

.outofstocktext:before {
  content: "";
  background: rgba(201, 201, 201, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.outofstocktext:after {
  content: "OUT OF STOCK";
  position: absolute;
  background: rgba(218, 189, 241, 0.9);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  font-size: 0.8em;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.data_table {
  font-family: Robota;
  font-size: 20px;
  font-weight: 400;
  color: #0e0e0e;
}

.gray {
  color: #4d4d4d;
  padding: 5px;
  font-size: 20px;
  font-weight: 400;
}

.heading {
  font-size: 24px;
  font-weight: 700 !important;
  font-family: IvyOra Text;
  text-transform: uppercase;
  color: #0e0e0e;
  line-height: 31.34px;
}

.heading1 {
  font-size: 24px;
  font-family: IvyOra Text;
  line-height: 31.34px;
  font-weight: 700;
  color: #0e0e0e;
}

.product_heading {
  font-size: 24px;
}

.font_family {
  font-size: 24px;
  font-family: IvyOra Text;
  /* font-style: normal; */
  font-weight: 500;
  /* font-size: 20px; */
  line-height: 31px;
  text-transform: uppercase;
  color: #0e0e0e;
}

.table {
  margin-top: 30px;
}

.button {
  background: #e9e7e7;
  color: black;
  border: none;
  padding: 5px 9px;
  border-radius: 15px;
  margin-left: 2%;
}

.btns {
  display: none;
}

.filterBtn {
  text-align: center;
}

.text_center {
  text-align: center;
}

.loader {
  width: 100%;
  height: 100vh;
  color: black;
}

.text_nav {
  cursor: pointer;
  text-transform: capitalize;
}

.top_breadum {
  text-transform: capitalize;
}

.sliderbox {
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
}

.slider_img {
  height: 350px;
  width: 100%;
}

.top_filter {
  /* height: 50px !important; */
  /* border-bottom: 1px solid black; */
  padding: 11px 0;

  /* overflow-x: auto;
  overflow-y: visible; */
  /* -ms-overflow-style: none; 
  scrollbar-width: none;  */
}

.top_filter::-webkit-scrollbar {
  display: none;
  height: 0;
}

.example {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.segment {
  color: #2a2381;
  font-weight: 500;
  cursor: pointer;
  /* width: 220px; */
  /* text-align: center; */
  /* border: 1px solid red; */
  text-transform: capitalize;
  max-height: 200px !important;
  overflow-y: auto;
  margin-left: 10px;
  min-width: fit-content;
  list-style: none;
  z-index: 3;
}

.sDrop {
  background: white;
  box-shadow: 0px 1px 4px -1px;
  /* width: 200px; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* padding: 5px 10px; */
  z-index: 3;
  position: relative;
  margin-left: 10px;
  max-height: 800px;
  overflow-y: auto;
}

.sDrop::-webkit-scrollbar {
  display: none;
  height: 0;
}

.top_filter1 {
  position: absolute;

  /* background: white;
  z-index: 6; */
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.top_filter1::-webkit-scrollbar {
  display: none;
  height: 0;
}

.sDrop_text {
  font-size: 16px;
  font-weight: 500;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: black;
  position: absolute;
  top: 118px;
}

.customH {
  width: 100% !important;
  height: 500px !important;
}

.customH1 {
  width: 100% !important;
  height: 100vh !important;
}

.model_text {
  color: black !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  border: none;
  background: none;
}

.model_text1 {
  color: black !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  border: none;
  background: none;
}

.innerFilter {
  width: 200px;
  padding-left: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.bg_light {
  background: #f1f1f1 !important;
}

.model_drop {
  width: 180px;
  background: green;
  height: 300px;
  /* position: absolute; */
  /* left: 200px; */
}

.manufacturedata {
  border: 2px solid red;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.catetgoryData {
  border: 2px solid red;
  width: 100%;
  /* text-align: center;
 align-items: center; */
  display: flex;
  justify-content: center;
  /* margin-left: 40px; */
}

.categoryCheck {

  text-align: center;
}


@media screen and (max-width: 407px) {
  .gray {
    color: #4d4d4d;
    padding: 5px;
    font-size: 15px !important;
    font-weight: 400;
    
  }
  .data_table{
    font-family: Robota;
    font-size: 15px;
    font-weight: 400;
    color: #0e0e0e;
  }
}

@media screen and (max-width: 992px) {
  .segment {
    margin-right: 30px !important;
  }

  .top_filter {
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 991px) {
  /* .categoryList{
    border: 2px solid red;
    margin-right: ;
    text-align: center;
  } */
  /* .categoryName{ */
  /* padding-left: 80px; */
  /* padding-right: 20px; */
  /* border: 2px solid red; */
  /* width: 100% !important; */
}

/* .manufactureName{ */
/* padding-left: 80px; */
/* } */
/* } */

@media screen and (max-width: 950px) {
  /* .categoryList {
    margin-left: -38px !important;
  } */
}

@media screen and (max-width: 899px) {
  .categories {
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
    /* padding-left: 20px; */
  }

  .categoryName {
    /* border: 2px solid red; */
    /* display: flex;
     justify-content: center; */
    padding-left: 140px;
    /* width: 75%; */
  }

  .manufactureName {
    padding-left: 140px;
  }

  /* .categoryList {
    margin-left: 140px ;
   
  }
  .manufactureList {
   
  } */
  .sDrop {
    width: 300px;
  }

  .hr {
    top: 200px;
  }
}

@media screen and (max-width: 899px) {
  .categories {
    margin-left: 50px !important;
  }

  .manufactureName {
    margin-left: 55px !important;
  }
}

@media screen and (max-width: 767px) {
  .categoryName {
    padding-left: 80px;
  }

  .manufactureName {
    padding-left: 80px;
  }
}

@media screen and (max-width: 600px) {
  /* .categoryList {
    margin-left: 120px !important;
    
  }
  .manufactureList {
    margin-left: 120px !important;
  } */
}

@media screen and (max-width: 550px) {
  /* .categoryList {
    margin-left: 0 auto !important;
  }
  .manufactureList {
    margin: 0 auto !important;
  } */
}

@media screen and (max-width: 500px) {
  .heading {
    /* font-size: 18px; */
  }

  .show {
    display: block;
  }
}

@media screen and (max-width: 499px) {
  .btns {
    display: block;
  }

  .filterBtn {
    display: none;
  }

  .heading {
    /* font-size: 20px !important; */
  }
}

@media screen and (max-width: 456px) {
  .innerFilter {
    width: 100%;
    padding-left: 10%;
  }
}

@media screen and (max-width: 470px) {
  
     
  .products-count{
    width: 40%;
  }
  

  .sDrop {
    left: 5%;
    width: 90%;
  }

  .categoryName {
    padding-left: 60px;
  }

  .manufactureName {
    padding-left: 60px;
  }
}

/* @media screen and (max-width: 400px) {
  .heading {
    font-size: 15px !important;
    font-weight: 500
  }
  .heading1{
    font-size: 20px !important;
    font-weight: 500;
    font-family: 
    IvyOra Text;
  }
  .text_nav{
    padding-top: 10px;
  }
  .item-para{
    padding-top: 10px !important;
}
} */


/* @media screen and (max-width: 899px) {
  .head_product{
    border: 1px solid red;
    padding-top: 60px !important;
  }
} */

/* no product found */
/* .npfTextO{
  font-family: "Roboto"!important;
  font-size: "28px"!important;
  font-weight: 600 !important;
  color:"#0E0E0E";
  text-align: center;

} */
/* .npfTextT{
  font-family: "Roboto";
  font-size: "24px";
  font-weight: 300;
  color:"#0E0E0E";
  text-align: center;
  
} */