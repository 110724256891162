.apply_coupon {
  border: 1px solid rgb(165, 165, 165);
  border-style: dashed;
  border-radius: 20px;
  /* padding: 15px */
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  max-width: 70% !important;
}

.coupon_heading {
  color: black !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: 50%;
  display: flex;
  justify-content: end;
}

.close_icon {
  display: flex;
  justify-content: end;
  width: 50%;
}

.coupon_card {
  border: 0.1px solid rgb(245, 244, 244);
  /* background: rgb(230, 229, 229) !important; */
  width: 25%;
  padding: 10px;
  margin: 5px;
  /* display: flex; */
  /* width: 100%; */
}

.applied_coupon_con {
  font-size: 16px !important;
  font-weight: 600;
  color: #000000;
  width: 60% !important;
}

.coupon_btn {
  background-color: white !important;
  border: none;
}

.discount-icon-title-div {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.img {
  width: 100%;
  height: 100%;
}

.heading1 {
  /* font: ; */
  font-family: IvyOra Text;
  font-weight: 500;
  font-size: 24px;
  line-height: 31.34px;
  text-transform: uppercase;
}

.img_border {
  border: 1px solid gray;
  border-radius: 4px;
}

.bottom-border {
  border-bottom: 1px solid black !important;
}

.text {
  font-size: 16px;
}

.text33 {
  font-size: 16px;
}

.apply-btn {
  text-align: center !important;
}

.s1-box {
  padding: 2px 10px;
  border-right: 1px solid gray;
}

.s2-box {
  padding: 2px 10px;
  border-right: 1px solid gray;
}

.s3-box {
  padding: 3px 8px;
  border: 1px solid gray !important;
}

.pointer {
  cursor: pointer;
  font-size: 12px;
}

.mrp-btn {
  padding: 4.8px 10px;
}

.mrp-btn-plus {
  padding: 2px 10px;
  border: none !important;
  background: white;
}

/* Side Box Style */

.text-1 {
  font-size: 12px !important;
  color: #0e0e0e;
}

text-2 {
}

.text-2 {
  font-size: 14px;
  margin-left: -10px;
}

.ngpadd1 {
  margin-left: -10px;
}

product_quantity {
  text-align: center;
  align-items: center;
  border: 2px solid red !important;
}

.negPadd {
  /* position: absolute; */
  margin-top: -7px;
}

.cart_border {
  border: 1px solid gray;
  border-radius: 10px;
}

.quality_border {
  border: 1px solid gray;
  border-radius: 5px;
}

.btn-cart {
  background: #da7b02 !important;
  color: white !important;
}

.btnn {
  background: #eebb2e;
  border-radius: 10px;
  border: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: justify;
  color: #fdfdfd;
  text-align: center;
}

.btnn button {
  background: none;
  border: none;
  background-color: none;
}

.customH {
  width: 100% !important;
  height: 500px !important;
}

.removeItemBtn {
  background: none;
  border: none;
  color: #0e0e0e;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.removeItemBtnDiv {
  border: 1px solid #da7b02;
  border-radius: 10px;
}

.removeItemBtnDivDim {
  width: 100%;
}

.spanBgCol {
  /* background-color: #a89393; */
  background-color: rgb(235, 235, 228);
  pointer-events: none;
}

.mrpWid {
}

.maincontainer {
  display: flex;
  /* justify-content: space-between; */
}

.promo-apply-input-div {
  /* width: 320px; */
  position: relative;
  padding-bottom: 15px;
}

.promo-apply-input-div-input {
  padding: 5px 15px;
  width: 100%;
  border-radius: 10px;
  border: 0.2px solid rgb(221 211 211);
}

.apply-promo-btn {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: 400;
  margin-top: 9.5px;
  color: rgb(145, 145, 145);
  cursor: pointer;
}

.apply-coupon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  color: black;
}

@media screen and (min-width: 768px) and (max-width: 1115px) {
  .promo-apply-input-div-input::placeholder {
    font-size: 15px !important;
  }

  .apply-promo-btn {
    position: absolute;
    right: 15px;
    font-size: 12px !important;
    font-weight: 400;
    margin-top: 10px;
    color: rgb(145, 145, 145);
  }

  .goto-home-btn {
    font-size: 14px !important;
    padding: 4px 5px !important;
  }
  .btnn {
    width: 20% !important;
  }

  .promo-apply-input-div-input::placeholder {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 767px) {
  .coupon_card h6 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .discount-icon-title-div {
    flex-direction: column !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px;
  }

  .pointer {
    color: black !important;
  }

  .apply-btn button b {
    text-align: center !important;
    font-size: 12px !important;
  }

  .maincontainer {
    display: flex;
    flex-direction: row !important;
  }

  .goto-home-btn {
    font-size: 12px !important;
    padding: 6px 6px !important;
  }
  .btnn {
    width: 30% !important;
  }
}

@media screen and (min-width: 350px) and (max-width: 513px) {
  .apply-btn button {
    text-align: center !important;
    font-size: 12px !important;
    padding: 0px;
  }

  .apply-coupon-div {
    display: flex;
    justify-content: baseline !important;
    align-items: center;
    padding-bottom: 4px;
    color: black;
    /* background: red; */
  }

  .pointer {
    color: black !important;
  }

  .removeItemBtnDivDim {
    width: 50%;
    margin: auto;
  }
  .goto-home-btn {
    font-size: 12px !important;
    padding: 6px 6px !important;
  }
  .btnn {
    width: 35% !important;
  }
}

@media (max-width: 349px) {
  .goto-home-btn {
    width: 100% !important;
    font-size: 12px !important;
    padding: 3px 2px !important;
  }
  .btnn {
    width: 50% !important;
  }
}

/* @media screen and (max-width: 850px) {
}
@media screen and (max-width: 950px) {
  .quantiy {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1000px) {
  .quantiy {
    margin-right: 15px;
  }
}

@media screen and (max-width: 950) {
  .text33 {
    margin-right: -22px !important;
  }
  .mrpage {
    width: 200px !important;
  }
} */
