.addressMian {
  display: flex;
  gap: 30px;
  font-size: 18px;
  font-weight: 400;
}

.addressContainer {
  display: flex !important;
  flex-direction: column;
  gap: 30px;
}

.icon {
  font-size: 20px;
}
.wesite {
  text-decoration: none;
}
.imG {
  width: 100px;
}

