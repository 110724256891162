* {
  /* font-size: 20px !important; */
}

.custom_btn {
  background: #2a2381;
}
.sidebar {
  width: 300px;
  height: 100vh;
  background: black;
  color: white;
  position: absolute;
  top: 0;
  z-index: 999;
}
.cursor_pointer {
  cursor: pointer;
}
.myAccount {
  font-family: IvyOra Text;
  font-weight: 700;
  font-size: 24px !important;
  line-height: 31.34px;
}
.profilepage {
  font-family: IvyOra Text !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26.11px !important;
}
.profile_Name {
  font-family: IvyOra Text !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26.11px !important;
}

.data {
  font-family: IvyOra Text !important;
}
