body {
  scroll-behavior: smooth !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid red !important;
  background: white !important;
}

.main_div1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border: 2px solid red !important;
  background: white !important;
}

.navigation:hover {
  color: black;
}

.Aboutus {
  font-family: "IvyOra Text";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  text-transform: uppercase;

  color: #0e0e0e;
}

.txt {
  color: #7e7e7e;
}

.aboutImg {
  height: 100%;
  width: 100%;
}

.contactImg {
  height: 100%;
  width: 100%;
}

.imgdiv {
  height: 330px;
  width: 100%;
}

.text_nav {
  cursor: pointer;
}

.text_nav:hover {
  color: gray;
}

.textarea {
  width: 100%;
  height: 100%;
  padding: 10px 10px;
}

.send-btn {
  color: white !important;
  background: #da7b02 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}

.customH {
  height: 500px !important;
}

.bold-600 {
  font-weight: 600 !important;
}

.h-auto {
  height: auto !important;
}

.capital {
  text-transform: capitalize !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.show {
  display: block !important;
}
.pointer {
  cursor: pointer !important;
}
