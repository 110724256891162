/* ===============================myOrder=============================== */
.items_container {
  background: #f8f8f8;
  border-radius: 10px;
}
.border1 {
  border: 1px solid #878484;
}
.product_details .img_box1 {
  border: 0.2px solid gray;
  border-radius: 10px;
  overflow: hidden;
  width: 30%;
}
.product_details .img_box1 {
  /* width: 182px; */
  height: 152px;
  background: #ffffff;
}
.product_details .img_box1 img {
  width: 100%;
  height:80%;
}
.items_details {
  font-weight: 500;
  color: #000000;
  font-size: 12px !important;
}
.order_history {
  font-family: IvyOra Text;
  font-weight: 700;
  font-size: 24px;
  line-height: 31.34px;
}

.main-container-css {
  min-height: 35vw !important;
}
@media only screen and (min-width: 1199px) and (max-width: 368px) {
  .product_details .img_box1 {
    /* margin-bottom: 4px !important; */
  }

}

@media (max-width: 822px) {
  .items_details {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .items_details {
    font-size: 12px;
  }
  .product_details .details {
    /* margin: 0px 20px; */
  }
  .product_details .img_box1 {
    /* width: 162px; */
    height: 132px;
    background: #ffffff;
  }
}

@media (max-width: 535px) {
  .items_details {
    font-size: 11px;
  }
  .product_details .img_box1 {
    /* width: 152px; */
    height: 122px;
    background: #ffffff;
  }
  .goto-home-btn {
    font-size: 12px !important;
    padding: 5px 1px !important;
  }
}

@media (max-width: 495px) {
  .items_details {
    font-size: 10px !important;
  }
  .goto-home-btn {
    font-size: 12px !important;
    padding: 5px 1px !important;
  }
}
@media (max-width: 468px) {
  .items_details {
    font-size: 9px;
  }
  .product_details .img_box1 {
    /* width: 122px; */
    height: 102px;
    background: #ffffff;
  }
  .goto-home-btn {
    font-size: 12px !important;
    padding: 5px 1px !important;
  }
}
@media (max-width: 397px) {
  .items_details {
    font-size: 6.5px !important;
  }
  .product_details .img_box1 {
    /* width: 92px; */
    height: 72px;
    background: #ffffff;
  }
  .goto-home-btn {
    font-size: 12px !important;
    padding: 5px 3px !important;
  }
}
/*  ========================checkout========================= */

.checkout_heading {
  font-weight: 500;
  font-family: "IvyOra Text";
  font-size: 24px;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

form .payment_checkbox {
  padding: 8px 16px;
  height: 40px;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

/* =========================order summery===================== */

.order_summary ul li img {
  width: 48px;
  height: 48px;
}
.order_summary ul li {
  border: 0;
}
.order_summary ul li h6 {
  display: flex;
  justify-content: center;
  align-self: center;
}
.order_summary ul button {
  background: #eebb2e !important;
  color: white !important;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
}

@media (min-width: 992px) {
  .order_summary ul li .small_para {
    font-size: 12px;
    font-weight: 500;
    color: #0e0e0e;
  }
}

@media (max-width: 402px) {
  .order_summary ul li .small_para {
    font-size: 12px;
    font-weight: 500;
    color: #0e0e0e;
  }

  .goto-home-btn {
    font-size: 12px !important;
    padding: 5px 1px !important;
  }
}

@media screen and (min-width: 398px) and (max-width: 442px) {
  .items_details {
    font-size: 7px !important;
  }
}
@media screen and (min-width: 468px) and (max-width: 490px) {
  .items_details {
    font-size: 9px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 601px) {
  .items_details {
    font-size: 10px !important;
  }
}
