.addAddress {
  background: white !important;
  color: black !important;
  font-size: 16px !important;
  border: 3px solid #eebb2e !important;
  width: fit-content !important;
}
.saveChanges {
  background: #eebb2e !important;
  color: white !important;
  font-size: 16px !important;
  width: fit-content !important;
  height: auto;
}


.cont {
  border: 0.5px solid #878484;
  border-radius: 0px 10px 10px 10px;
  margin-bottom: 20px;
}
.fontFamily{
  font-family: 'Roboto';
  font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 23px;
color: #0E0E0E;
text-align: justify;

}

