.first_page {
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "IvyOra Text";
  color: #f1f1f1;
}

.arrowDownIcon {
  position: relative;
  bottom: -95%;
}

.mainImageSlider {
  width: 100%;
  /* height: 100; */
  display: flex;
  /* justify-content: end; */
  /* padding-top: 20%; */
  padding-left: 5%;
}

.main_container12 {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.bikeImage {
  background-image: url("/public/images/homeImage1.png");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: right;
  padding-top: 40px;
  /* padding: 40px; */
  /* background-size: cover; */
}

.imageSlider {
  width: 30%;
  height: 200px;
  background-image: url("/public/images/spare1.png");
  background-size: 100% 100%;
  animation: changeImage 8s linear infinite;
  opacity: 10;
}

@keyframes changeImage {
  0% {
    background-image: url("/public/images/spare1.png");
  }

  25% {
    background-image: url("/public/images/spare2.png");
  }

  50% {
    background-image: url("/public/images/spare3.png");
  }

  75% {
    background-image: url("/public/images/spare4.png");
  }
}

.search_btn {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #2a2381 !important;
  text-transform: capitalize !important;
}

.search_btn1 {
  width: 100%;
  height: 30px;
  display: flex;
  color: white !important;
  align-items: center;
  background-color: rgb(3, 14, 60) !important;
  text-transform: capitalize !important;
}

.top_text_box {
  /* width: 36%; */
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  margin-top: 10%;
  /* border: 1px solid red; */
}

.top_texts1 {
  font-size: 35px;
  font-style: italic;
}

.top_texts2 {
  font-size: 17px;
  margin-top: -12px;
}

.flex_item_center {
  display: flex;
  align-items: center;
}

.select_style {
  background-color: #3f3f3f !important;
  width: 100% !important;
  padding: 5px !important;
  text-align: center !important;
  color: white !important;
  border-radius: 5px !important;
  font-size: 18px !important;
  border: none !important;
  text-transform: capitalize !important;
}

.option_style {
  background: #555555;
  text-align: center;
  text-transform: capitalize !important;
}

.explore_btn {
  font-family: "Roboto";
  padding: 10px 15px;
  /* color: #FFFFFF; */
  color: #000000 !important;
  background: none;
  display: flex;
  align-items: center;
  border: 1px solid #000000 !important;
  border-radius: 7px;
  margin-top: 30px;
  font-weight: 400;
  font-size: 20px;
  font-family: Roboto;
}

.explore_btn1 {
  font-family: "Roboto";
  padding: 4px 6px;
  /* color: #FFFFFF; */
  color: #ffffff !important;
  background: #da7b02;
  display: flex;
  align-items: center;
  border: 1px solid #da7b02;
  border-radius: 7px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  font-family: Roboto;
}

.explore_btn:hover {
  background: rgba(0, 0, 0, 0.4);
}

.text-orang {
  color: #000000 !important;
}

.emoji {
  height: 45px;
  width: 44px;
}

.emoji1 {
  height: 25px;
  width: 30px;
}

.selectresponsive {
  background-color: #3f3f3f;
  width: 100%;
  padding: 5px;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  font-family: "Roboto";
}

.optionResponsive {
  font-family: "Roboto";
  font-size: 18px;
}

.option2Res {
  background-color: #555555;
  text-align: center;
}

.find_txt {
  font-family: "IvyOra Text";
  font-size: 18px;
}

.main_div {
  background-color: #252424;
  border-radius: 5px;
  margin: auto;
  max-width: 1300px;
}

.first_div {
  height: 20%;
}

.h2_txt {
  /* color: white; */
  color: #000000 !important;
  font-family: IvyOra Text;
  font-style: italic;
  font-size: 56px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 55.12px;
}

.h2_txt1 {
  /* color: white; */
  color: #000000 !important;
  font-family: IvyOra Text;
  font-style: italic;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  /* line-height: 55.12px; */
}

.promoheader {
  /* background-image:linear-gradient(#DA7B02 100%,#EEBB2E 50%); */
  background-image: url("/public/images/promobackgrond.png");
}

.promdiscount {
  /* padding-top: 4%; */
  color: #010101;
}

.promodate {
  color: #010101;
}

.promoname {
  color: #e08913;
  font-family: Roboto;
  font-size: 40px;
  font-weight: 900;
}

.promoCode {
  border-radius: 30px;
  background-color: rgba(233, 233, 233, 1);
  color: #b0b0b0;
  padding: 3% 15%;
}

.cart-promo-texts {
  font-size: 18px !important;
}
.promobutton {
  border-radius: 30px !important;
  background-color: #010101 !important;
  color: #ffffff !important;
  padding: 3% 15% !important;
}

.minimumorder {
  margin-top: 5px;
}

.p_txt {
  /* color: white; */
  color: #000000 !important;
  width: 300px;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 400;
  font-family: Roboto;
}

.p_txt1 {
  /* color: white; */
  color: #000000 !important;
  width: 300px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
}

.promo-code-price {
  color: #e08913;
}

.home_data {
  display: flex;
  align-items: center;
  justify-content: start;
}

.div_emoji {
  border: 1px solid gray;
  border-radius: 5px;
  width: 60px;
  padding: 5px;
  height: 55px;
  margin: 55px 15px 0px 0px;
  background-color: #f1f1f1;
}

.div_emoji1 {
  border: 1px solid gray;
  border-radius: 5px;
  width: 40px;
  padding: 5px;
  height: 35px;
  margin: 55px 15px 0px 0px;
  background-color: #f1f1f1;
}

.div_emoji:hover {
  background: rgba(0, 0, 0, 0.4);
}

.text-Ivy {
  font-family: IvyOra Text !important;
}

.pr_4 {
  padding-right: 30px;
}

.filter_bg {
  background: #252424 !important;
}

.res_text {
  font-size: 18pt !important;
  color: #f1f1f1 !important;
}

@media (max-width: 400px) {
  .img_text_con {
    width: 0% !important;
  }
  .cart-promo-text {
    font-size: 16px !important;
  }
}

@media (max-width: 599px) {
  .img_text_con {
    width: 10% !important;
  }
  .cart-promo-text {
    font-size: 16px !important;
  }
}

@media (max-width: 901px) {
  .pr_4 {
    padding-right: 0;
  }

  .res_text {
    font-size: 16pt !important;
  }
}

@media (max-width: 575px) {
  .pb_1 {
    padding-bottom: 5px;
  }

  .res_text {
    font-size: 14pt !important;
  }

  .cart-promo-text {
    font-size: 16px !important;
  }
}
