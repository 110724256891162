.saveChanges2 {
    background: #eebb2e !important;
    color: white !important;
    font-size: 16px !important;
    /* border: 2px solid forestgreen; */
    width: fit-content !important;
    /* padding: 5px; */
    /* height: 85px; */
    border-radius: 8px;
    border: 2px solid #eebb2e;
    height: auto;
    padding: 10px;
  }
  .saveChanges3 {
    background: #eebb2e !important;
    color: white !important;
    font-size: 16px !important;
    /* border: 2px solid forestgreen; */
    width: fit-content !important;
    /* padding: 5px; */
    /* height: 85px; */
    border-radius: 8px;
    border: 2px solid #eebb2e;
    height: auto;
    padding: 10px;
  }
  .saveChanges2:hover {
    background-color: white !important;
    color: #0e0e0e !important;
    border: 2px solid #f6b607 !important;
  }

   @media (max-width: 485px) { 
  
    .saveChanges2{
      padding:10px;
      font-size: 12px !important;
    }
    .saveChanges3{
      padding:10px;
      font-size: 12px !important;
    }
    } 

    @media (max-width: 440px) { 
  
      .saveChanges2{
        padding:8px;
        font-size: 10px !important;
      }
      .saveChanges3{
        padding:8px;
        font-size: 10px !important;
      }
      } 
      