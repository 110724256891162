/* new code */

.saveChanges1 {
  background: #eebb2e !important;
  color: white !important;
  font-size: 12px;
  border-radius: 8px;
  width: 100%;
  border: 2px solid #eebb2e;
  padding: 10px;
}

.saveChanges1:hover {
  background-color: white !important;
  color: #0e0e0e !important;
  border: 2px solid #f6b607 !important;
}

.cont {
  border: 0.5px solid #878484;
  border-radius: 0px 10px 10px 10px;
}

.editBtn {
  width: 63px;
  background: #eebb2e;
  background: #2a2381;
  border-radius: 5px;
  font-family: "Roboto";
  color: #fdfdfd;
}

.editBtn button {
  background: none;
  border: none;
  color: #fdfdfd;
}

.cont label {
  padding-bottom: 0.75rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 520;
  font-size: 20px;
  line-height: 23px;

  text-align: justify;
  font-variant: normal;

  color: #0e0e0e;
}

.divBtn {
  height: 47px;
}

.bttn {
  background: #eebb2e;
  border-radius: 10px;
  border: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* line-height: 23px; */
  padding-left: 15px;
  padding-right: 15px;
  text-align: justify;
  color: #fdfdfd;
  text-align: center;
}

.saveChanges2 {
  background: #eebb2e !important;
  color: white !important;
  font-size: 16px !important;
  /* border: 2px solid forestgreen; */
  /* width: fit-content !important; */
  /* padding: 5px; */
  /* height: 85px; */
  border-radius: 8px;
  border: 2px solid #eebb2e;
  /* height: auto; */
  padding: 10px;
}
.saveChanges3 {
  background: #eebb2e !important;
  color: white !important;
  font-size: 16px !important;
  /* border: 2px solid forestgreen; */
  /* width: fit-content !important; */
  /* padding: 5px; */
  /* height: 85px; */
  border-radius: 8px;
  border: 2px solid #eebb2e;
  /* height: auto; */
  padding: 10px;
}

.saveChanges2:hover {
  background-color: white !important;
  color: #0e0e0e !important;
  border: 2px solid #f6b607 !important;
}

.addresss {
  padding-left: 40px;
  height: auto;

}

@media screen and (max-width:683px) {}


@media screen and (max-width:533px) {
  .saveChanges2 {
    /* width: 20px; */
    /* border: 2px solid red; */
    /* height: 80px !important; */
  }
}

@media screen and (max-width: 515px) {

  .saveChanges2 {
    /* height: 80px !important; */
  }
}

@media screen and (max-width: 502px) {
  .saveChanges2 {
    /* height: 88px !important; */
  }
}

@media screen and (max-width: 498px) {}

@media screen and (max-width: 450px) {
  .addresss {
    padding-left: 40px;
    height: auto;
  }

  .saveChanges2 {
    /* height: 88px !important; */
    width: 100%;
  }

  .mainbtndiv {
    /* width: 100%;
    margin-left: -30px; */
  }

  .calcelbtn {
    margin-left: 30px;
  }
}

@media screen and (max-width: 400px) {
  .calcelbtn {
    margin-left: 80px;
  }

  .editpagebtns {
    width: 100%;
    margin-left: 40px;
  }

  .addAddressBts {
    width: 100%;
    margin-left: 45px;
  }
}

@media screen and (max-width: 534px) {
  .saveChanges2 {
    padding: 0;
    font-size: 10px;
  }
}

/* old code */
/* .cont {
    margin-top: 2%;
    padding-left: 10% !important;
    padding-right: 10% !important;
    border: 0.5px solid #878484;
  border-radius: 0px 10px 10px 10px;
  padding-bottom: 5%;
  } */

/* .cont label {
    padding-bottom: 0.75rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 520;
    font-size: 20px;
    line-height: 23px;
   
    text-align: justify;
    font-variant: normal;
  
    color: #0e0e0e;
  } */

/* .divBtn {
    height: 47px;
  
    display: flex;
    justify-content: space-between;
    margin-top: 6%;
  }
   .secondbtn {
    background: #eebb2e;
    border-radius: 10px;
    border: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: justify;
    color: #fdfdfd;
    text-align: center;
  }

  .firstbtn{
    border-radius: 10px;
    background-color: white;
    border: 3px solid #eebb2e;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: justify;
    color:black;
    margin-right: 10px;
    text-align: center;
    
  }

  .Firstpadd {
    padding-top: 8%;
  } */