/* new css */
.mContainer {
  font-family: "Roboto" !important;
}

/* .divBtn { */
/* height: 43px; */
/* display: flex;
  justify-content: end; */
/* } */
.bttn {
  background: #eebb2e;
  border-radius: 5px;
  border: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  padding-left: 15px;
  padding-right: 15px;
  padding: 15px;
  text-align: justify;
  color: #fdfdfd;
  text-align: center;
}

/* old css */
/* ===============================myOrder=============================== */

.items_container {
  background: #f8f8f8;
  border-radius: 10px;
}

.product_details .img_box {
  border: 0.2px solid gray;
  border-radius: 10px;
}

.product_details .img_box {
  width: 182px;
  height: 152px;
  background: #ffffff;
}

.product_details .img_box img {
  width: 100%;
  height: auto;
}

.items_details {
  font-weight: 500;
  color: #000000;
  font-size: 16px;
}
.small_para{
   text-transform: capitalize;
   }
   .small_para1{
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
     Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

@media only screen and (min-width: 1199px) and (max-width: 355px) {
  .product_details .img_box {
    margin-bottom: 4px !important;
  }
}

@media (max-width: 822px) {
  .items_details {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .items_details {
    font-size: 13px;
  }

  .product_details .details {
    margin: 0px 20px;
  }

  .product_details .img_box {
    width: 162px;
    height: 132px;
    background: #ffffff;
  }
}

@media (max-width: 563px) {
  .items_details {
    font-size: 12px;
  }

  .product_details .img_box {
    width: 152px;
    height: 122px;
    background: #ffffff;
  }
}

@media (max-width: 495px) {
  .items_details {
    font-size: 11px;
  }
}

@media (max-width: 468px) {
  .items_details {
    font-size: 9px;
  }

  .product_details .img_box {
    width: 122px;
    height: 102px;
    background: #ffffff;
  }
}

@media (max-width: 397px) {
  .items_details {
    font-size: 6.5px;
  }

  .product_details .img_box {
    width: 92px;
    height: 72px;
    background: #ffffff;
  }
}

/*  ========================checkout========================= */

.checkout_heading {
  font-weight: 500;
  font-family: "IvyOra Text";
  font-size: 24px;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

form .payment_checkbox {
  padding: 8px 16px;
  height: 40px;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

form label {
  font-weight: 500;
}

.customH {
  width: 100%;
  height: 500px;
}

/* =========================order summery===================== */

.order_summary ul li img {
  width: 48px;
  height: 48px;
}

.order_summary ul li {
  border: 0;
}

.order_summary ul li h6 {
  align-self: center;
}

.order_summary ul button {
  background: #eebb2e !important;
  color: white !important;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
}

@media (min-width: 992px) {
  .order_summary ul li .small_para {
    font-size: 12px;
    font-weight: 500;
    color: #0e0e0e;
  }
}

@media (max-width: 402px) {
  .order_summary ul li .small_para {
    font-size: 12px;
    font-weight: 500;
    color: #0e0e0e;
  }
}

@media (max-width: 351px) {
  .order_summary ul li img {
    display: none;
    background: none;
    border: none;
  }

  .order_summary ul li .rounded {
    border: none !important;
  }
}

@media screen and (max-width: 1000px) {
  .order_summary {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {

  /* .checkoutPage {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .checkoutPage order_summary:nth-of-type(1) {
    order: 2;
  }
  .checkoutPage save_address:nth-of-type(2) {
    order: 1;
  } */
  .order_summary {
    order: 2;
  }

  .save_address {
    order: 1;
  }
}

/* @media screen and (max-width: 400) {
  .bttn {
   
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .divBtn {
    display: flex !important;
    justify-content: center !important;
  }
}
@media screen and (max-width: 1200) {
  .divBtn {
    display: flex;
    justify-content: end !important;
    margin-left: 100px !important;
  }
} */
/* @media screen and (max-width: 570) {
  .divBtn {
    display: flex !important;
    justify-content: center !important;
    width: 20px !important;
    padding: 0 20px !important;
    margin-right: 100px !important;
  }
  .bttn {
    margin: 0 auto !important;
  }
} */
/* @media screen and (max-width: 450) {
  .divBtn {
    display: flex !important;
    justify-content: center !important;
    width: 20px;
    
  }
} */
@media screen and (max-width: 392px) {
  .font-adress {

    padding: 0 5px;
  }

  /* .font-adress1{ */
  /* font-size: 15px; */
}